import {
    OK_HTTP_CODE,
    BAD_REQUEST_HTTP_CODE,
    NOT_FOUND_HTTP_CODE,
    CREATED_HTTP_CODE,
    CONFLICT_HTTP_CODE,
} from '@configs/http-codes';

const NEW_USER_PIN = '654321';
const RETURNING_USER_PIN = '123456';

class HttpError extends Error {
    constructor(message = '', code = BAD_REQUEST_HTTP_CODE, status = 'error') {
        super(`Request failed with status code ${code}`);

        this.data = {
            message,
            code,
            status,
        };
    }
}

const createResponse = options => {
    const { data = null, error = '', status = OK_HTTP_CODE } = options || {};

    return Promise.resolve({
        data,
        error: status < BAD_REQUEST_HTTP_CODE ? null : new HttpError(error, status),
        status,
    });
};

export default () => ({
    /*
     * POST /api/v1/loyalty/phone-verification
     */
    verifyPhoneNumber({ phoneNumber }) {
        if (phoneNumber === undefined) {
            return createResponse({
                error: 'Missing required field: phoneNumber.',
                status: BAD_REQUEST_HTTP_CODE,
            });
        }

        if (!phoneNumber) {
            return createResponse({
                error: 'Phone number cannot be empty.',
                status: BAD_REQUEST_HTTP_CODE,
            });
        }

        // TODO: Handle UNAUTHORIZED_CODE

        return createResponse({
            data: {},
        });
    },

    /*
     * POST /api/v1/loyalty/register
     */
    verifyPin({ pin }) {
        if (pin === RETURNING_USER_PIN) {
            return createResponse({
                data: {},
            });
        }

        if (pin === NEW_USER_PIN) {
            return createResponse({
                data: {},
                status: CREATED_HTTP_CODE,
            });
        }

        // TODO: Handle UNAUTHORIZED_CODE

        return createResponse({
            error: 'Invalid PIN code.',
            status: CONFLICT_HTTP_CODE,
        });
    },

    /*
     * POST /api/v1/loyalty/resend-pin
     */
    resendPin() {
        // TODO: Handle UNAUTHORIZED_CODE

        return createResponse({
            data: {},
        });
    },

    /*
     * GET /api/v1/loyalty/member
     */
    memberInfo(notFound = false) {
        // TODO: Handle UNAUTHORIZED_CODE

        if (notFound) {
            return createResponse({
                status: NOT_FOUND_HTTP_CODE,
            });
        }

        return createResponse({
            data: {},
        });
    },
});
