<template>
    <div class="account-menu">
        <BannerInfo
            v-if="showAccountVerificationWarning"
            class="account-verification-block"
            :variant="BANNER_VARIANT"
            :title="$t('Confirm your email address')"
            :description="$t('We have sent a verification link to your e-mail address.')"
        >
            <template #button>
                <ButtonBanner
                    :variant="BUTTON_BANNER_VARIANT"
                    :disabled="isLoading"
                    @click="sendVerification()"
                >
                    {{ $t('Send link again') }}
                </ButtonBanner>
            </template>
        </BannerInfo>

        <ol>
            <CellList
                v-for="(accountTooltipLink, index) in accountTooltipLinks"
                :key="index"
                class="account-menu-item"
                :label="accountTooltipLink.name"
                :divider="index + 1 < accountTooltipLinks.length"
                :horizontal-padding="CELL_LIST_PADDING"
                :variant="CELL_LIST_VARIANT"
                @click="$router.push(accountTooltipLink.path)"
            >
                <template v-if="accountTooltipLink.icon" #icon>
                    <Icon :width="iconSize" :height="iconSize" :icon="accountTooltipLink.icon" />
                </template>
            </CellList>
        </ol>

        <div class="button-container">
            <ButtonSmall :variant="LOGOUT_BUTTON_VARIANT" :wide="true" @click="onLogout()">
                {{ $t('Logout') }}
            </ButtonSmall>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { ACCOUNT_VERIFICATION_STATES } from '@configs/account-verification';
import { MODAL_ACCOUNT_VERIFICATION, MODAL_ACCOUNT_VERIFICATION_TYPE } from '@configs/modals';

import { CUSTOMER_ACCOUNT_MODIVO_CLUB_NAME } from '@router/names';

import getTranslatedAccountLinks from '@assets/account-links';
import { isModivoClubTestEnabled } from '@assets/modivo-club';

import { MODULE_NAME as CLIENT_MODULE_NAME } from '@analytics-modules/client/meta';
import {
    CONFIRM_EMAIL_HEADER_TOOLTIP_VERIFICATION_CLICK,
    CONFIRM_EMAIL_HEADER_TOOLTIP_VIEW,
} from '@analytics-modules/client/types/Events';

import { HEADER_MY_ACCOUNT_MODAL_LOGOUT_BUTTON_CLICK } from '@analytics-types/Events';

import { ButtonSmall, BUTTON_SMALL_VARIANTS } from '@modivo-ui/components/ButtonSmall/v2';
import { BannerInfo, BANNER_INFO_VARIANTS } from '@modivo-ui/components/BannerInfo/v2';
import { ButtonBanner, BUTTON_BANNER_VARIANTS } from '@modivo-ui/components/ButtonBanner/v2';
import {
    CellList,
    CELL_LIST_VARIANTS,
    CELL_LIST_PADDINGS,
} from '@modivo-ui/components/CellList/v2';
import { Icon } from '@modivo-ui/components/Icon/v1';

const { mapActions: mapCustomerActions } = createNamespacedHelpers('customer');
const { mapActions: mapAvatarActions, mapGetters: mapAvatarGetters } = createNamespacedHelpers(
    'avatar'
);
const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'AccountMenu',

    components: {
        CellList,
        ButtonSmall,
        BannerInfo,
        ButtonBanner,
        Icon,
    },

    data() {
        return {
            isLoading: false,
        };
    },

    computed: {
        ...mapAvatarGetters(['canVerifyAccount']),
        ...mapConfigGetters(['isAccountVerificationEnabled', 'storeCode']),

        showAccountVerificationWarning() {
            return this.isAccountVerificationEnabled && this.canVerifyAccount;
        },
    },

    beforeCreate() {
        this.LOGOUT_BUTTON_VARIANT = BUTTON_SMALL_VARIANTS.SECONDARY;
        this.BANNER_VARIANT = BANNER_INFO_VARIANTS.WARNING;
        this.BUTTON_BANNER_VARIANT = BUTTON_BANNER_VARIANTS.WARNING;
        this.CELL_LIST_PADDING = CELL_LIST_PADDINGS.PX_16;
        this.CELL_LIST_VARIANT = CELL_LIST_VARIANTS.ACTION;

        this.iconSize = 24;

        this.accountTooltipLinks = getTranslatedAccountLinks(
            this.$t.bind(this),
            this.$tPath.bind(this)
        ).filter(
            ({ key }) =>
                key !== CUSTOMER_ACCOUNT_MODIVO_CLUB_NAME || isModivoClubTestEnabled(this.$abTests)
        );
    },

    mounted() {
        if (this.showAccountVerificationWarning) {
            this.$analytics.moduleEmit(CLIENT_MODULE_NAME, CONFIRM_EMAIL_HEADER_TOOLTIP_VIEW);
        }
    },

    methods: {
        ...mapCustomerActions(['logout']),
        ...mapAvatarActions(['sendAccountVerification']),

        async sendVerification() {
            this.isLoading = true;
            this.$analytics.moduleEmit(
                CLIENT_MODULE_NAME,
                CONFIRM_EMAIL_HEADER_TOOLTIP_VERIFICATION_CLICK
            );

            const isSuccess = await this.sendAccountVerification();

            if (isSuccess) {
                await this.$modals.open(MODAL_ACCOUNT_VERIFICATION, {
                    [MODAL_ACCOUNT_VERIFICATION_TYPE]: ACCOUNT_VERIFICATION_STATES.PENDING,
                });
            }

            this.isLoading = false;
        },

        async onLogout() {
            this.$analytics.emit(HEADER_MY_ACCOUNT_MODAL_LOGOUT_BUTTON_CLICK);

            await this.logout();
        },
    },
};
</script>

<style scoped lang="scss">
.account-menu {
    .account-verification-block {
        @apply px-ui-4;
    }

    .account-menu-item {
        @apply cursor-pointer;
    }

    .button-container {
        @apply px-ui-4 mb-ui-6 mt-ui-2;
    }
}
</style>
