const createResponse = options => {
    const { data = null, error = '' } = options || {};

    return Promise.resolve({
        data,
        error,
    });
};
const INVALID_CARD_NUMBER = '111111';
const SPENT_CARD_NUMBER = '000000';
const EXPIRED_CARD_NUMBER = '999999';
const VALID_PIN_NUMBER = '123456';

export default () => ({
    /*
     * POST
     */
    addGiftCard({ cardNumber, pin }) {
        if (cardNumber === SPENT_CARD_NUMBER) {
            return createResponse({
                error: 'SPENT_CARD_NUMBER',
            });
        }

        if (cardNumber === EXPIRED_CARD_NUMBER) {
            return createResponse({
                error: 'EXPIRED_CARD_NUMBER',
            });
        }

        if (cardNumber === INVALID_CARD_NUMBER) {
            return createResponse({
                error: 'INVALID_CARD_NUMBER',
            });
        }

        if (pin !== VALID_PIN_NUMBER) {
            return createResponse({
                error: 'INVALID_PIN_NUMBER',
            });
        }

        // TODO: Handle UNAUTHORIZED_CODE

        return createResponse({
            data: {},
        });
    },

    removeGiftCard({ cardNumber }) {
        console.log(cardNumber);

        return createResponse({
            data: {},
        });
    },

    updateGiftCards(data) {
        console.log(data);

        return createResponse({
            data: {},
        });
    },
});
