import {
    AVAILABLE,
    UNAVAILABLE,
    AVAILABLE_FROM_ANOTHER_SELLER_AT_MODIVO,
    AVAILABLE_FROM_ANOTHER_SELLER_AT_PARTNER,
} from '@types/ProductAvailability';

import { transformCartItem } from '@assets/cart';
import { getFirstOfferFromVariant } from '@assets/product-offers';

import { formatPrice } from '@core-assets/price';

import ShippingMethod from '@models/ShippingMethod/ShippingMethod';

export default {
    cartFetched: state => {
        return !!state.cartData;
    },

    carts: (state, getters) => {
        if (!getters.cartFetched || !state.cartData?.extCart?.store_carts?.length) {
            return [];
        }

        return state.cartData.extCart.store_carts.map(store => ({
            ...store,
            items: store.items.map(cartItem => {
                const {
                    id,
                    sku: skuVariant,
                    product: { sku, name, url_key },
                    quantity,
                    prices,
                } = cartItem;

                const {
                    discount_percent: cartDiscountPercent,
                    original_price: regularPriceTotal,
                    formatted_original_price: regularPriceTotalFormatted,
                    final_price: finalPriceTotal,
                    formatted_final_price: finalPriceFormattedTotal,
                    final_item_price: finalPrice,
                    item_original_price: regularPrice,
                    formatted_final_item_price: finalPriceFormatted,
                    formatted_item_original_price: regularPriceFormatted,
                    discount_percent_by_special_price: discountPercent,
                } = prices;

                return {
                    ...cartItem,
                    id,
                    sku,
                    skuVariant,
                    name,
                    quantity,
                    url_key,
                    price: {
                        discount: discountPercent === 0 ? null : `${discountPercent}%`,
                        cartDiscount: cartDiscountPercent === 0 ? null : `${cartDiscountPercent}%`,
                        regular: {
                            amount: regularPrice,
                            formatted: regularPriceFormatted,
                        },
                        regularTotal: {
                            amount: regularPriceTotal,
                            formatted: regularPriceTotalFormatted,
                        },
                        promotional: {
                            amount: finalPrice,
                            formatted: finalPriceFormatted,
                        },
                        promotionalTotal: {
                            amount: finalPriceTotal,
                            formatted: finalPriceFormattedTotal,
                        },
                    },
                };
            }),
        }));
    },

    transformedCarts: (_, getters, rootState, rootGetters) => {
        return getters.carts.map(cart => ({
            ...cart,
            items: cart.items.map(item =>
                transformCartItem(
                    item,
                    rootState.loyaltyClub.statusesData,
                    rootGetters['config/locale'],
                    rootGetters['config/currency'],
                    rootGetters['loyaltyClub/pointPrice']
                )
            ),
        }));
    },

    cartItems: (_, getters) => {
        return getters.carts.flatMap(({ items }) => items);
    },

    transformedCartItems: (_, getters) => {
        return getters.transformedCarts.flatMap(({ items }) => items);
    },

    cartItemsCount: (_, getters) => {
        return getters.cartItems.reduce((count, item) => count + item.quantity, 0);
    },

    complexProductDataItemsSkusMap: state => {
        return Object.fromEntries(state.complexProductDataItems.map(item => [item.id, item]));
    },

    availabilityStatuses: (_, getters) => {
        return Object.fromEntries(
            getters.carts.flatMap(({ store, items }) =>
                items.map(({ id, sku, chosenSize, quantity }) => {
                    if (getters.isProductInCartAvailable(id)) {
                        return [id, AVAILABLE];
                    }

                    const variant =
                        getters.complexProductDataItemsSkusMap[sku]?.variants[chosenSize];

                    if (variant && getFirstOfferFromVariant(variant)?.quantity >= quantity) {
                        return [
                            id,
                            store.internal
                                ? AVAILABLE_FROM_ANOTHER_SELLER_AT_MODIVO
                                : AVAILABLE_FROM_ANOTHER_SELLER_AT_PARTNER,
                        ];
                    }

                    return [id, UNAVAILABLE];
                })
            )
        );
    },

    isCartEmpty: (_, getters) => {
        return !getters.cartItemsCount;
    },

    selectedShippingMethods: (_, getters) => {
        const NOT_SELECTED_METHOD = new ShippingMethod().getModel();

        return getters.carts.map(({ selected_shipping_method }) => {
            return (
                selected_shipping_method || {
                    ...NOT_SELECTED_METHOD,
                }
            );
        });
    },

    appliedCouponCode: (state, getters) => {
        if (getters.cartFetched) {
            const {
                cartData: { applied_coupons: appliedCoupons },
            } = state;

            if (appliedCoupons && appliedCoupons.length) {
                return appliedCoupons[0].code;
            }
        }

        return null;
    },

    isLoaderActive: state => !!state.loaderText,

    deliveryCost: (_, getters, __, rootGetters) => {
        const { deliveryCostNumber } = getters;

        if (deliveryCostNumber !== null) {
            return formatPrice(
                deliveryCostNumber,
                rootGetters['config/locale'],
                rootGetters['config/currency']
            );
        }

        return null;
    },

    deliveryCostNumber: (_, getters) => {
        const { selectedShippingMethods } = getters;

        const prices = selectedShippingMethods
            .map(({ price }) => price)
            .filter(price => price !== null)
            .filter(price => price !== '');

        if (prices.length) {
            return prices.reduce((total, price) => total + price, 0);
        }

        return null;
    },

    subTotal: (state, getters) => {
        if (getters.cartFetched) {
            const { formatted_subtotal_including_tax: subTotal } = state.cartData.extCart.prices;

            return subTotal;
        }

        return '';
    },

    discount: (state, getters) => {
        if (getters.cartFetched) {
            const { discount } = state.cartData.extCart.prices;

            if (discount) {
                return discount.amount.extFormat;
            }
        }

        return '';
    },

    discountNumber: (state, getters) => {
        if (getters.cartFetched) {
            const { discount } = state.cartData.extCart.prices;

            if (discount) {
                const {
                    amount: { value },
                } = discount;

                return value;
            }
        }

        return null;
    },

    discountLabel: (state, getters) => {
        if (!getters.cartFetched) {
            return '';
        }

        const label = state.cartData.extCart?.prices?.discount?.label || [];

        return label.join(', ');
    },

    loyaltyClubDiscount: (state, getters) => {
        if (getters.cartFetched) {
            const { discounts } = state.cartData.extCart.prices;

            if (discounts) {
                const separateDiscount = discounts.find(discount => discount.is_separate);

                return separateDiscount ? separateDiscount.amount.extFormat : '';
            }
        }

        return '';
    },

    loyaltySummaryPoints: (state, getters) => {
        if (getters.cartFetched) {
            const { mfc_points } = state.cartData.extCart;

            return mfc_points || 0;
        }

        return 0;
    },

    dutyAmount: (state, getters) => {
        if (getters.cartFetched) {
            const { duty } = state.cartData.extCart.prices;

            if (duty) {
                const { formatted_amount: dutyAmount } = duty;

                return dutyAmount;
            }
        }

        return null;
    },

    grandTotal: (state, getters) => {
        if (getters.cartFetched) {
            const { formatted_grand_total: grandTotal } = state.cartData.extCart.prices;

            return grandTotal;
        }

        return '';
    },

    grandTotalNumber: (state, getters) => {
        if (getters.cartFetched) {
            const { grand_total: grandTotal } = state.cartData.extCart.prices;

            return grandTotal.value;
        }

        return null;
    },

    subTotalWithDiscount: (state, getters) => {
        if (getters.cartFetched) {
            const {
                formatted_subtotal_with_discount_excluding_tax: subtotalWithDiscount,
            } = state.cartData.extCart.prices;

            return subtotalWithDiscount;
        }

        return '';
    },

    subTotalWithDiscountNumber: (state, getters) => {
        if (getters.cartFetched) {
            const {
                value: subtotalWithDiscountNumber,
            } = state.cartData.extCart.prices.subtotal_with_discount_excluding_tax;

            return subtotalWithDiscountNumber;
        }

        return '';
    },

    unavailableProductsInCart: (_, getters) => {
        return getters.cartItems.filter(({ availability }) => {
            return availability?.is_available === false;
        });
    },

    unavailableProductIdsInCart: (_, getters) => {
        return getters.unavailableProductsInCart.map(({ id }) => id);
    },

    isProductInCartAvailable: (_, getters) => id => {
        return !getters.unavailableProductIdsInCart.includes(id);
    },

    cartProductsSkus: (_, getters) => {
        return getters.cartItems.map(({ product: { sku } }) => sku);
    },

    uniqueAvailablePromotions: (_, getters) =>
        getters.cartItems
            .filter(({ product }) => product.hasPromoSticker && product.promoSticker.code)
            .map(({ product }) => product.promoSticker)
            .reduce(
                (promoStickers, promoSticker) =>
                    promoStickers.filter(({ code }) => code === promoSticker.code).length
                        ? promoStickers
                        : [...promoStickers, promoSticker],
                []
            ),

    isAnyGiftCardActive: state => state.giftCards.some(({ isActive }) => isActive),

    areAnyGiftCardsStored: state => !!state.storedGiftCards?.length,

    giftCardCovered: state =>
        state.giftCards
            .filter(({ isActive }) => isActive)
            .reduce((sum, { covered }) => sum + (covered?.amount || 0), 0),
};
