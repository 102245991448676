export const HOME_PAGE = '/';
export const LOGIN_PAGE = 'login';
export const REGISTER_PAGE = 'register';
export const FORGOT_PASSWORD_PAGE = 'forgot-password';
export const CHECKOUT_PAGE = 'checkout';
export const CHECKOUT_ACCOUNT_PAGE = 'checkout/account';
export const CHECKOUT_CART_PAGE = 'checkout/cart';
export const CHECKOUT_SUCCESS_PAGE = 'checkout/success';
export const WISHLIST_PAGE = 'wishlist';
export const PACKAGE_TRACKING_PAGE = 'package-tracking';
export const ADD_RETURN_PAGE = 'add-return';
export const SUCCESS_ADD_RETURN_PAGE = 'success-add-return';
export const FAILED_ADD_RETURN_PAGE = 'failed-add-return';
export const NEWSLETTER_PAGE = 'newsletter';
export const NEWSLETTER_SMS_UNSUBSCRIBE_PAGE = 'us';
export const NEWSLETTER_SUBSCRIBE_PAGE_URL_KEY = 'subscribe';
export const NEWSLETTER_UNSUBSCRIBE_PAGE_URL_KEY = 'unsubscribe';
export const NEWSLETTER_UNSUBSCRIBE_BY_CODE_PAGE_URL_KEY = 'unsubscribe-by-code';
export const RESET_PASSWORD = 'reset-password';
export const ORDER_PAGE = 'order';
export const CUSTOMER_PAGE = 'customer';
export const CUSTOMER_DETAILS_PAGE = 'details';
export const CUSTOMER_ADDRESSES_PAGE = 'addresses';
export const CUSTOMER_ACCOUNT_EDIT_PAGE = 'edit';
export const CUSTOMER_ACCOUNT_ORDERS_PAGE = 'orders';
export const CUSTOMER_ACCOUNT_ORDER_VIEW_PAGE = 'orders/:orderId';
export const CUSTOMER_ACCOUNT_AGREEMENTS_PAGE = 'agreements';
export const CUSTOMER_ACCOUNT_RETURNS_PAGE = 'returns';
export const CUSTOMER_ACCOUNT_RETURN_PAGE = 'return';
export const CUSTOMER_ACCOUNT_MODIVO_CLUB_PAGE = 'modivo-club';
export const CUSTOMER_ACCOUNT_SUCCESS_RETURN_PAGE = 'success-return';
export const CUSTOMER_ACCOUNT_FAILED_RETURN_PAGE = 'failed-return';
export const CUSTOMER_ACCOUNT_CARDS_PAGE = 'cards';
export const CHECKOUT_REPAYMENT = 'checkout/repayment';
export const CMS_PAGE = 'b';
export const CONTACT_PAGE = 'contact';
export const PAGE_BUILDER_PATH = 'pb';
export const LIVE_PAGE = 'live';
export const LIVESTREAM_PAGE = 'supervision';
export const LOYALTY_CLUB_PAGE = '/fashion-club';
export const NOT_SUPPORTED_PAGE = 'not-supported';
export const WEB_LINKS_PAGE = 'web-links';
export const PREFERENCES_FORM_PAGE = 'preferences-form';
export const MOBILE_TOKEN_EXPIRED_PAGE = 'token-expired';
export const JOIN_MODIVO_CLUB_PAGE = 'join-modivo-club';
