import { GIFT_CARD_CODE } from '@configs/checkout-payment-methods';

import { PAYMENT_TYPES } from '@types/PaymentTypes';

export const GIFT_CARD_DATA_MOCK_FULL_COVER_STAY = [
    {
        isActive: true,
        cardNumber: '1111222233334444',
        covered: { amount: 50, formatted: '50 zł' },
        stay: { amount: 50, formatted: '50 zł' },
        additionalPaymentMethod: '',
    },
];

export const GIFT_CARD_DATA_MOCK_FULL_COVER_NOSTAY = [
    {
        isActive: true,
        cardNumber: '2222333344445555',
        covered: { amount: 100, formatted: '100 zł' },
        stay: { amount: 0, formatted: '0 zł' },
        additionalPaymentMethod: '',
    },
];

export const GIFT_CARD_DATA_MOCK_PARTIAL_COVER = [
    {
        isActive: true,
        cardNumber: '3333444455556666',
        covered: { amount: 100, formatted: '50 zł' },
        stay: { amount: 0, formatted: '0 zł' },
        additionalPaymentMethod: 'blik_payment',
    },
];

export const GIFT_CARD_DATA_MOCK_DEACTIVATED = [
    {
        isActive: false,
        cardNumber: '4444555566667777',
        additionalPaymentMethod: '',
    },
];

export const GIFT_CARD_DATA_MOCKS = {
    GIFT_CARD_DATA_MOCK_FULL_COVER_STAY,
    GIFT_CARD_DATA_MOCK_FULL_COVER_NOSTAY,
    GIFT_CARD_DATA_MOCK_PARTIAL_COVER,
    GIFT_CARD_DATA_MOCK_DEACTIVATED,
};

export const GIFT_CARD_METHOD_MOCK = {
    additionalInfo: null,
    code: GIFT_CARD_CODE,
    description: 'Karta podarunkowa',
    disallowedForCompany: true,
    disallowedForPrivatePerson: false,
    disallowedForGiftCard: true,
    id: '376970d1-4112-4af3-9c81-1c76053da10b',
    title: 'Karta podarunkowa',
    type: PAYMENT_TYPES.ONLINE,
};
